<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
       
      </div> -->

      <div class="player listmain type-f3d f3d game-f3d game-f3d-lm">
        <div class="data cdx_d">
          <h3>{{rowsData[0].title}}</h3>
          <!-- 大 -->
          <ul class="custome_ul">
            <li v-for="ele in rowsData[0].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol style="width:80px" class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>

        </div>
        <!-- 小 -->
        <template v-for="(item,index) in rowsData">

          <div v-if="index>0" class="data " :key="index">
            <h3>{{item.title}}</h3>
            <ul class="custome_ul">
              <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
                <ol class="td_rate">{{ele.Odds}}</ol>
                <ol class="td_cash">
                  <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                  <div class="quick-check" v-if="ele.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click="closeCheck(ele)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </li>

            </ul>
          </div>
        </template>
        <!--  -->

      </div>
      <yushe :disabled="isDisabled" />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'lm',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "大", arr: [] },
        { title: "小", arr: [] },
        { title: "单", arr: [] },
        { title: "双", arr: [] },
        { title: "质", arr: [] },
        { title: "合", arr: [] },
      ],
      labelArr: ["佰", "拾", "个", "佰拾尾数", "佰个尾数", "拾个尾数", "总数"],
    };
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 大
        let arr1 = this.ddFilterData1(
          resAArr,
          [3326, 3332, 3338, 3664, 3670, 5358, 3690],
          this.labelArr
        );
        this.rowsData[0].arr = arr1;
        // 小
        let arr2 = this.ddFilterData1(
          resAArr,
          [3327, 3333, 3339, 3665, 3671, 5359, 3691],
          this.labelArr
        );
        this.rowsData[1].arr = arr2;
        // 单
        let arr3 = this.ddFilterData1(
          resAArr,
          [3328, 3334, 3340, 3666, 3672, 5360, 3692],
          this.labelArr
        );
        this.rowsData[2].arr = arr3;
        // 双
        let arr4 = this.ddFilterData1(
          resAArr,
          [3329, 3335, 3341, 3667, 3673, 5361, 3693],
          this.labelArr
        );
        this.rowsData[3].arr = arr4;
        // 质
        let arr5 = this.ddFilterData1(
          resAArr,
          [3330, 3336, 3342, 3668, 5356, 5362, 5410], //5362后面少一个质 总数 现在是假ID 3693
          this.labelArr
        );
        this.rowsData[4].arr = arr5;
        // 合
        let arr6 = this.ddFilterData1(
          resAArr,
          [3331, 3337, 3343, 3669, 5357, 5363, 5411], //5362后面少一个质 总数 现在是假ID 3693
          this.labelArr
        );
        this.rowsData[5].arr = arr6;

        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li{
  min-height: 26px!important;
  height: 26px!important;
}
.listmain.game-f3d-lm .data.cdx_d {
  width: calc(25% - 2px);
}
.listmain.game-f3d-lm .data {
  margin-right: 2px;
  width: calc((75% - 10px) / 5);
}
</style>